@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);.wallet-adapter-button {
    background: #fff!important;
    border-radius: 6px!important;
    color: #000!important;
    padding: 14px 45px!important;
    transition: all .3s ease!important
}

.wallet-adapter-button span:before {
    content: url(/public/media/profile-white.svg);
    position: relative;
    right: 10px;
    vertical-align: middle
}

.wallet-adapter-button:hover {
    background-color: #fff
}

.wallet-adapter-button:hover span {
    color: #000
}

.wallet-adapter-button-trigger {
    background: #fff!important;
    border-radius: 6px!important;
    color: #000!important;
    padding: 14px 45px!important;
    transition: all .3s ease!important
}

.wallet-adapter-button-trigger span:before {
    content: url(/public/media/profile-white.svg)!important;
    position: relative!important;
    right: 10px!important;
    vertical-align: middle!important
}

.wallet-adapter-button-trigger:hover {
    background-color: #fff!important
}

.wallet-adapter-button-trigger:hover span {
    color: #000!important
}

.wallet-adapter-button-trigger:hover span:before {
    content: url(/public/media/profile-white.svg)!important
}

.wallet-adapter-button:not([disabled]):hover {
    background-image: linear-gradient(rgba(0,0,0,.15) 0 0)
}

.wallet-adapter-button-end-icon {
    margin-left: 8px
}

.wallet-adapter-button-start-icon {
    margin-right: 8px
}

.wallet-adapter-dropdown-list-item {
    background: #404144
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-image: linear-gradient(rgba(0,0,0,.15) 0 0)
}

.wallet-adapter-modal-button-close {
    background: none;
    border-radius: 6px;
    padding: 10px;
    right: 10px;
    top: 10px
}

.wallet-adapter-modal-logo {
    max-width: 100%
}

.wallet-adapter-modal-logo-wrapper {
    align-items: center;
    background: #141515;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: 90px;
    justify-content: center;
    margin-bottom: 10px;
    padding: 12px;
    width: 90px
}

.wallet-adapter-modal-wrapper {
    background: #2c2d30;
    font-family: Inter,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: 600;
    padding: 40px 20px 20px
}

.wallet-adapter-modal-wrapper-no-logo {
    padding-top: 30px
}

.wallet-adapter-modal-title {
    font-size: 34px;
    font-weight: 600;
    line-height: 41px;
    margin-bottom: 27px;
    margin-top: 0;
    width: 100%
}

@media (max-width: 374px) {
    .wallet-adapter-modal-title {
        font-size:26px
    }
}

.wallet-adapter-modal-list li:not(:first-of-type) {
    margin-top: 12px
}

* {
    border: 0;
    margin: 0;
    padding: 0
}

:after,:before {
    box-sizing: border-box
}

:active,:focus,a:active,a:focus {
    outline: none
}

aside,footer,header,nav {
    display: block
}

body,html {
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 100%;
    font-size: 14px;
    height: 100%;
    line-height: 1;
    width: 100%
}

button,input,textarea {
    font-family: inherit;
    font-size: inherit
}

input::-ms-clear {
    display: none
}

button {
    cursor: pointer
}

button::-moz-focus-inner {
    border: 0;
    padding: 0
}

a,a:hover,a:visited {
    text-decoration: none
}

ul li {
    list-style: none
}

img {
    vertical-align: top
}

input::-webkit-inner-spin-button,input::-webkit-outer-spin-button {
    -webkit-appearance: none
}

a,h1,h2,h3,h4,h5,h6,p {
    color: inherit;
    font-size: inherit;
    font-weight: 400
}

html {
    scroll-behavior: smooth
}

body {
    color: #fff;
    font-family: Inter;
    overflow-x: hidden
}

h3 {
    font-size: 34px;
    font-weight: 700;
    line-height: 120%
}

.wrapper {
    background: linear-gradient(143.25deg,#120c18 33.58%,#280314 116.2%);
    width: 100%
}

.container {
    margin: 0 auto;
    max-width: 1300px;
    padding: 0 10px;
    width: 100%
}

.header {
    width: 100%
}

.header__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px 0
}

.header__items {
    align-items: center;
    display: flex;
    gap: 40px
}

.header__item {
    font-size: 17px;
    font-weight: 600;
    line-height: 100%;
    position: relative
}

.link:after {
    background-color: #fff;
    bottom: -10px;
    content: " ";
    height: 3px;
    left: 0;
    position: absolute;
    transition: .3s;
    width: 0
}

.link:hover:after {
    width: 100%
}

.header__item.btn {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 6px;
    padding: 14px 45px;
    transition: all .3s ease
}

.header__item.btn span:before {
    content: url(/public/media/profile-white.svg);
    position: relative;
    right: 10px;
    vertical-align: middle
}

.header__item.btn:hover {
    background-color: #fff
}

.header__item.btn:hover span {
    color: #000
}

.header__item.btn:hover span:before {
    content: url(/public/media/profile-white.svg)
}

.about {
    margin-top: 40px
}

.about__content {
    align-items: center;
    background: radial-gradient(166.33% 2191.22% at 13.19% 29.17%,#430c2d 0,#430c2d 23.53%,#0b1142 100%);
    border-radius: 8px;
    box-shadow: inset 0 0 44px hsla(0,0%,100%,.1);
    display: flex;
    height: 286px;
    justify-content: space-between;
    overflow: hidden;
    width: 100%
}

.about-text__box {
    padding: 55px 35px 55px 47px;
    width: 50%
}

.about-text__box-author {
    color: #ff4995;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%
}

.about-text__box-title {
    margin: 12px 0
}

.about-text__box-text {
    color: #ffffffd9;
    font-size: 17px;
    font-weight: 500;
    line-height: 140%
}

.about-text__box-text span {
    color: #fff;
    font-weight: 600
}

.about__img {
    -webkit-transform: translateY(35px);
    transform: translateY(35px)
}

.connect {
    margin-top: 20px
}

.connect__content {
    align-self: center;
    background: radial-gradient(150.83% 1602.61% at 9.62% 4.83%,#201b27 0,rgba(27,8,22,0) 100%);
    border-radius: 8px;
    box-shadow: inset 0 0 44px hsla(0,0%,100%,.1);
    display: flex;
    justify-content: space-between;
    width: 100%
}

.connect-text__box {
    padding: 47px;
    width: 35%
}

.connect-text__box-title {
    margin-bottom: 8px
}

.connect-text__box-text {
    color: #ffffffbf;
    font-size: 17px;
    font-weight: 500;
    line-height: 140%
}

.connect-text__box-btn img {
    background: radial-gradient(126.27% 1719.2% at 2.55% 181.11%,#ff1476 0,#2b45d0 73.93%,#23a9d1 100%);
    border-radius: 6px;
    box-shadow: inset 0 0 25px rgba(74,213,255,.18);
    margin-top: 16px;
    padding: 16px 86px;
    transition: all .3s ease
}

.connect-text__box-btn img:hover {
    box-shadow: inset 0 0 25px #ffffffcf
}

.connect__token {
    align-items: flex-start;
    align-self: center;
    -webkit-backdrop-filter: blur(78px);
    backdrop-filter: blur(78px);
    background: linear-gradient(164.44deg,hsla(0,0%,93%,.1) 10.89%,rgba(194,196,216,.1) 95.36%);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin-right: 37px;
    padding: 14px;
    width: 50%
}

.connect__token-box {
    width: 91%
}

.connect__token-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 135%
}

.connect__token-text {
    color: hsla(0,0%,100%,.75);
    font-size: 17px;
    font-weight: 500;
    line-height: 140%
}

.connect__token-border {
    background: #ffffff26;
    height: 1px;
    margin: 12px 0;
    width: 100%
}

.instructions {
    margin-top: 20px
}

.instructions__content {
    align-items: center;
    background: radial-gradient(150.83% 1602.61% at 9.62% 4.83%,#201b27 0,rgba(27,8,22,0) 100%);
    border-radius: 8px;
    box-shadow: inset 0 0 44px hsla(0,0%,100%,.1);
    display: flex;
    flex-direction: column;
    padding: 47px 0;
    width: 100%
}

.instructions__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 93%
}

.instructions__header-title {
    margin-bottom: 8px
}

.instructions__header-subtitle {
    color: #e84288;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%
}

.instructions__border {
    background-color: #603f67;
    height: 1px;
    margin: 30px 47px;
    width: 93%
}

.instructions__body {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 93%
}

.instructions__box-card-title {
    align-items: center;
    display: flex;
    margin-bottom: 22px
}

.instructions__box-card-title p {
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    margin-left: 12px
}

.instructions__box-border {
    background: hsla(0,0%,100%,.25);
    height: 1px;
    width: 3.5%
}

.instructions__box-text {
    background: radial-gradient(162.96% 918.5% at -49.26% -57.33%,#e84288 0,#221727 63.63%);
    border-radius: 8px;
    padding: 22px;
    width: 21%
}

.instructions__box-text p {
    color: #ed7bb7;
    font-size: 17px;
    font-weight: 500;
    line-height: 135%;
    text-align: center
}

.claim {
    margin-top: 230px
}

.claim__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%
}

.claim__title {
    font-size: 48px;
    margin-bottom: 12px;
    text-align: center
}

.claim__text {
    color: #ffffffbf;
    font-size: 17px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 24px;
    text-align: center
}

.claim__btn {
    background: linear-gradient(0deg,#fff,#fff),radial-gradient(126.27% 1719.2% at 2.55% 181.11%,#ff1476 0,#2b45d0 73.93%,#23a9d1 100%);
    border-radius: 6px;
    box-shadow: inset 0 0 25px rgba(74,213,255,.18);
    color: #000;
    font-size: 17px;
    font-weight: 700;
    line-height: 100%;
    padding: 16px 86px;
    transition: all .3s ease
}

.claim__btn span:before {
    content: url(/public/media/logo-black.svg);
    position: relative;
    right: 10px;
    vertical-align: middle
}

.waves {
    margin-top: 10px;
    width: 100%
}

.warning_message_wrap {
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(75px);
    background: radial-gradient(133.98% 1011.6% at 0 0,rgba(255,0,0,.24) 0,hsla(0,0%,100%,0) 100%,hsla(0,100%,70%,0) 0);
    border-radius: 5px;
    bottom: 20px;
    box-shadow: inset -2.9528px -2.9528px 147.64px hsla(0,0%,100%,.02);
    position: fixed;
    transition: all .3s ease-in-out;
    z-index: 9999
}

.warning_message {
    border-radius: 5px;
    color: #fff;
    flex-direction: row;
    font-size: 18px;
    gap: 12px;
    padding: 16px 26px 16px 16px
}

.warning_icon,.warning_message {
    align-items: center;
    display: flex
}

.warning_icon {
    -webkit-backdrop-filter: blur(24.8035px);
    backdrop-filter: blur(24.8035px);
    background: linear-gradient(99.51deg,hsla(0,0%,100%,.08) -35.49%,hsla(0,0%,100%,0) 134.53%);
    border: 1px solid hsla(0,0%,100%,.1);
    border-radius: 5px;
    height: 32px;
    justify-content: center;
    width: 32px
}

@media screen and (max-width: 1440px) {
    .container {
        max-width:1100px
    }

    .connect__token-box {
        width: 88%
    }
}

@media screen and (max-width: 1200px) {
    .container {
        width:1040px
    }

    .about__content {
        flex-direction: column;
        height: auto
    }

    .about-text__box {
        width: 70%
    }

    .instructions__box-text p {
        font-size: 14px
    }
}

@media screen and (max-width: 1199px) {
    .container {
        width:960px
    }

    .instructions__box-card {
        width: 35%
    }

    .instructions__box-card-img {
        width: 100%
    }
}

@media screen and (max-width: 991px) {
    .container {
        width:720px
    }

    .connect-text__box,.connect__content {
        align-items: center;
        flex-direction: column
    }

    .connect-text__box {
        display: flex;
        justify-content: center;
        width: 70%
    }

    .connect-text__box-text {
        text-align: center
    }

    .connect__token {
        margin-bottom: 37px;
        margin-right: 0;
        width: 70%
    }
}

@media screen and (max-width: 767px) {
    .container {
        width:540px
    }

    .link {
        display: none
    }

    .about-text__box {
        align-items: center;
        display: flex;
        flex-direction: column
    }

    .about-text__box-text {
        align-self: center
    }

    .instructions__body {
        flex-direction: column
    }

    .instructions__box-card {
        width: 75%
    }

    .instructions__box-text {
        width: 50%
    }

    .instructions__box-border {
        height: 27px;
        margin: 20px 0;
        width: 1px
    }

    .instructions__border,.instructions__header {
        width: 75%
    }

    .instructions__box-card-title p {
        font-size: 18px
    }

    .connect__token-box {
        width: 84%
    }

    .connect-text__box {
        width: 75%
    }
}

@media screen and (max-width: 580px) {
    .container {
        width:300px
    }

    h3 {
        font-size: 26px
    }

    .header__item.btn {
        font-size: 14px;
        padding: 14px 35px
    }

    .connect__token {
        align-items: center;
        flex-direction: column;
        width: 80%
    }

    .connect__token-title {
        font-size: 18px
    }

    .connect__token-text {
        text-align: center
    }

    .connect__token-img {
        margin-bottom: 20px
    }

    .connect-text__box-title {
        font-size: 22px;
        text-align: center
    }

    .connect-text__box-btn img {
        margin: 0 auto;
        padding: 16px 82px
    }

    .connect-text__box {
        align-items: flex-start;
        padding: 16px 0;
        width: 90%
    }

    .instructions__header {
        align-items: flex-start;
        flex-direction: column
    }

    .instructions__header-img-one {
        margin-top: 20px
    }

    .instructions__header-img-two {
        display: none
    }

    .claim {
        margin-top: 100px
    }

    .about-text__box-text {
        font-size: 14px
    }

    .about__img {
        -webkit-transform: translateX(74px) translateY(-35px) rotate(5deg);
        transform: translateX(74px) translateY(-35px) rotate(5deg);
        width: 155%
    }

    .claim__title {
        font-size: 28px;
        width: 75%
    }

    .connect__token-text {
        font-size: 14px;
        text-align: left
    }

    .connect__token-box {
        width: 100%
    }

    .connect-text__box-text {
        font-size: 14px;
        margin-bottom: 22px;
        text-align: left
    }

    .header__content {
        flex-direction: column
    }

    .header__item.btn {
        margin-top: 20px
    }

    .about__content {
        height: 640px
    }

    .claim__text {
        font-size: 14px;
        width: 80%
    }

    .claim__btn {
        padding: 16px 65px
    }

    .about-text__box {
        align-items: flex-start;
        padding: 32px 16px;
        width: 90%
    }

    .instructions__header-subtitle {
        font-size: 12px
    }

    .instructions__header {
        width: 90%
    }

    .instructions__content {
        align-items: center;
        padding-top: 16px
    }

    .instructions__border {
        width: 90%
    }

    .instructions__box-border {
        display: none
    }

    .instructions__box-text {
        background: inherit;
        margin-left: 16px;
        margin-right: auto;
        order: 3;
        padding: 0;
        width: 75%
    }

    .instructions__box-text p {
        color: #ffffff80;
        font-size: 17px;
        font-weight: 500;
        line-height: 135%;
        text-align: left
    }

    .instructions__box-card {
        margin-bottom: 20px;
        width: 90%
    }

    .instructions__header-img-one {
        display: none
    }

    .instructions__header-img-two {
        display: inline;
        margin-left: 20px;
        margin-right: auto;
        margin-top: 36px
    }
}

.adjustWallet {
    align-items: center!important;
    align-self: center!important;
    height: 30px!important;
    text-align: center!important
}

.wallet-adapter-button {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: DM Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    padding: 0 24px
}

.wallet-adapter-button-trigger {
    background-color: #512da8
}

.wallet-adapter-button:not([disabled]):focus-visible {
    outline-color: #fff
}

.wallet-adapter-button:not([disabled]):hover {
    background-color: #1a1f2e
}

.wallet-adapter-button[disabled] {
    background: #404144;
    color: #999;
    cursor: not-allowed
}

.wallet-adapter-button-end-icon,.wallet-adapter-button-end-icon img,.wallet-adapter-button-start-icon,.wallet-adapter-button-start-icon img {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px
}

.wallet-adapter-button-end-icon {
    margin-left: 12px
}

.wallet-adapter-button-start-icon {
    margin-right: 12px
}

.wallet-adapter-collapse {
    width: 100%
}

.wallet-adapter-dropdown {
    display: inline-block;
    position: relative
}

.wallet-adapter-dropdown-list {
    grid-row-gap: 10px;
    background: #2c2d30;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0,0,0,.6);
    display: grid;
    font-family: DM Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
    grid-template-rows: 1fr;
    list-style: none;
    margin: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 100%;
    transition: opacity .2s ease,visibility .2s,-webkit-transform .2s ease;
    transition: opacity .2s ease,transform .2s ease,visibility .2s;
    transition: opacity .2s ease,transform .2s ease,visibility .2s,-webkit-transform .2s ease;
    visibility: hidden;
    z-index: 99
}

.wallet-adapter-dropdown-list-active {
    opacity: 1;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    visibility: visible
}

.wallet-adapter-dropdown-list-item {
    align-items: center;
    border: none;
    border-radius: 6px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    justify-content: center;
    outline: none;
    padding: 0 20px;
    white-space: nowrap;
    width: 100%
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-color: #1a1f2e
}

.wallet-adapter-modal-collapse-button svg {
    fill: #999;
    align-self: center
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: -webkit-transform .15s ease-in;
    transition: transform .15s ease-in;
    transition: transform .15s ease-in,-webkit-transform .15s ease-in
}

.wallet-adapter-modal {
    background: rgba(0,0,0,.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .15s linear;
    z-index: 1040
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
    opacity: 1
}

.wallet-adapter-modal-button-close {
    align-items: center;
    background: #1a1f2e;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 12px;
    position: absolute;
    right: 18px;
    top: 18px
}

.wallet-adapter-modal-button-close:focus-visible {
    outline-color: #fff
}

.wallet-adapter-modal-button-close svg {
    fill: #777;
    transition: fill .2s ease 0s
}

.wallet-adapter-modal-button-close:hover svg {
    fill: #fff
}

.wallet-adapter-modal-overlay {
    background: rgba(0,0,0,.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0
}

.wallet-adapter-modal-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 3rem;
    min-height: calc(100vh - 6rem)
}

@media (max-width: 480px) {
    .wallet-adapter-modal-container {
        margin:1rem;
        min-height: calc(100vh - 2rem)
    }
}

.wallet-adapter-modal-wrapper {
    align-items: center;
    background: #10141f;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0,0,0,.6);
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    font-family: DM Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
    max-width: 400px;
    position: relative;
    z-index: 1050
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
    width: 100%
}

.wallet-adapter-modal-title {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin: 0;
    padding: 64px 48px 48px;
    text-align: center
}

@media (max-width: 374px) {
    .wallet-adapter-modal-title {
        font-size:18px
    }
}

.wallet-adapter-modal-list {
    list-style: none;
    margin: 0 0 12px;
    padding: 0;
    width: 100%
}

.wallet-adapter-modal-list .wallet-adapter-button {
    border-radius: 0;
    font-size: 18px;
    font-weight: 400
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon,.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,.wallet-adapter-modal-list .wallet-adapter-button-start-icon,.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
    height: 28px;
    width: 28px
}

.wallet-adapter-modal-list .wallet-adapter-button span {
    font-size: 14px;
    margin-left: auto;
    opacity: .6
}

.wallet-adapter-modal-list-more {
    align-items: center;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    padding: 12px 24px 24px 12px
}

.wallet-adapter-modal-list-more svg {
    fill: #fff;
    margin-left: .5rem;
    transition: all .1s ease
}

.wallet-adapter-modal-list-more-icon-rotate {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.wallet-adapter-modal-middle {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px;
    width: 100%
}

.wallet-adapter-modal-middle-button {
    background-color: #512da8;
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 18px;
    margin-top: 48px;
    padding: 12px;
    width: 100%
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #222;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    margin: 0
}

code {
    font-family: source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace
}

/*# sourceMappingURL=main.340b4258.css.map*/
